<template>
	<div class="login-body flex flex-column align-items-center justify-content-center">
    <Toast/>

    <div class="login-panel p-5 pt-6 p-fluid flex flex-column justify-content-center">
        <h4 class="font-bold">Dýnamis</h4>
        <span class="mb-4 line-height-3">Bem vindo a <span class="font-bold">Dýnamis</span>!<br/>Por favor, preencha os dados para  login </span>

        <span class="p-input-icon-left mb-3">
            <i class="pi pi-user"></i>
            <InputText v-model="formData.email" type="text" placeholder="Endereço de E-mail" />
        </span>

        <span class="p-input-icon-left mb-6">
            <i class="pi pi-key"></i>
            <InputText v-model="formData.password" type="password" placeholder="Senha" />
        </span>

        <Button type="button" label="Login" :loading="loadingBtn" @click="logar()"/>


    </div>

<!--    <span class="mt-4">Don’t you have an account, <span class="font-bold cursor-pointer" @click="goDashboard">sign up</span></span>-->
</div>

</template>

<script>
//import axios from 'axios'
import { mapActions } from 'vuex'
import api from "@/axios";
import router from "@/router";
// import api from '../axios'

	export default {
		data() {
			return {
        loadingBtn: false,
				checked: false,
        formData: {
          email: '',
          password: '',
          //password: 'oioioi',
        }
			}
		},
		methods: {
      ...mapActions({
        signIn: 'store/auth/signIn'
      }),
			goDashboard(){
				this.$router.push({ path: '/' });
			},
      async logar(){
        this.loadingBtn = true
				console.log('logar')
        // console.log(this.formData)
        // this.$store.dispatch('login', this.formData)

        api.get('/sanctum/csrf-cookie').then(() => {
          // console.log('response csrf-cookie')
          // console.log(response)

          api.post('api/login', this.formData).then(async resuser => {
            // console.log('response login')
            // console.log(resuser.data)

            // console.log('token')
            // console.log(resuser.data['accessToken'])

            router.push('/')
            localStorage.setItem('token', resuser.data['accessToken'])
          }).catch(error => {
            console.log('error')
            console.log(error)
            this.$toast.add({severity:'error', summary: 'Informação Inonsistente', detail:'E-mail ou senha não corresponde', life: 3000})
            this.loadingBtn = false
          });
        });





			},
		}
	}
</script>

<style scoped>

</style>
